.com-dashboard-container h2 {
	font-weight: bold;
	font-size: 22px;
}

.com-dashboard-container h3 {
	font-weight: bold;
	font-size: 18px;
}

.w-100 {
	width: 100%;
}
.text-right {
	text-align: right;
}

.eds-card {
	padding: var(--eds-space-4) var(--eds-space-8);
}

.eds-vertical-navbar__strip {
	padding: 0px;
}

.eds-vertical-navbar__strip ul {
	overflow: auto;
}

#order-graph,
.com-chart {
	width: 100%;
	height: 250px;
}

.grid-item-value {
	font-weight: 700;
}

.grid-item-label {
	font-weight: 500;
}

.eds-grid .grid-item-value,
h3.grid-component-title,
.grid-component-title {
	font-size: 14px;
	font-weight: bold;
}

.grid-component-header {
	position: relative;
}

.grid-component-icon {
	position: absolute;
	right: 2px;
}

.eds-grid .grid-item-label {
	font-size: 12px;
}

.eds-grid .eds-card {
	border: 1px solid #ccc;
}

.dashboard-container {
	padding: 16px 32px;
}

.com-card-flex {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.com-table thead tr th {
	background-color: grey;
	color: #fff;
}

.com-table .first-column-header tbody tr td:first-child {
	background-color: #eee;
}

.com-dashboard-title {
	font-size: 28px;
}

.com-table .eds-table-heading,
.com-table .eds-table-cell {
	padding: var(--eds-space-4);
}

.percent-deviation-list > li {
	padding: 0px 4px;
	border-left: 1px solid #000;
}

.percent-deviation-list > li > ul {
	padding-left: 4px;
}

.percent-deviation-list > li:last-child {
	padding: 0px 0px 0px 4px;
	border-right: 0px;
}

.percent-deviation-list > li:first-child {
	padding: 0px 4px 0px 0px;
	border-left: 0px;
}

.world-map-widget {
	background-image: url("../images/world-map.png");
	width: 100%;
	height: 322px;
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-position: center;
	position: relative;
}

.world-map-widget .map-pointer {
	position: absolute;
}

.world-map-widget .map-pointer-wrapper {
	position: relative;
	background-color: #fff;
	text-align: center;
	font-weight: bold;
	border: 1px solid;
	border-bottom: 3px solid;
	padding: 2px;
}

.world-map-widget .map-pointer-wrapper:after {
	content: "";
	position: absolute;
	top: 100%;
	left: calc(50% - 8px);
	border-top: 10px solid;
	border-top-color: inherit;
	border-left: 8px solid transparent;
	border-right: 8px solid transparent;
}
.world-map-widget .map-pointer-right:after {
	content: "";
	left: calc(100% - 14px);
}
.world-map-widget .map-pointer-left:after {
	content: "";
	left: 0px;
}
.world-map-widget .map-pointer-label {
	font-size: 10px;
	line-height: 8px;
}
.world-map-widget .map-pointer-value {
	font-size: 12px;
}
.tooltip-container {
	width: 260px;
	color: #fff;
	font-weight: normal;
	padding: 4px;
	font-size: 12px;
}
.tooltip-container li {
	padding-bottom: 4px;
}
