@import "@nike/eds/dist/index.css";
body {
  width: 100% ;
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'sans-serif', 'Sans Serif';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.qualtrics-survey-container {
  position: fixed;
  top: 50%;
  right: 0px;
  height: 640px;
  width: 400px;
  background: #999;
  margin-top: -320px;
  -webkit-transition: right 0.5s ease-in-out;
  -moz-transition: right 0.5s ease-in-out;
  -o-transition: right 0.5s ease-in-out;
  transition: right 0.5s ease-in-out;
}

.qualtrics-survey-container.qualtrics-survey-container-hide{
  right: -400px;
}

.qualtrics-survey-container .qualtrics-survey-wrapper{
  position: relative;
  width: 100%;
  height: 100%;
}

.qualtrics-survey-container .qualtrics-survey-button {
  transform: rotate(270deg);
  position: absolute;
  top: 50%;
  left: -105px;
  margin-top: -24px;
  border-radius: 2px;
}

.qualtrics-survey-container .qualtrics-survey-frame {
  width: 100%;
  height: 100%;
  border: 1px solid grey;
}