.com-chart-container{
    position: relative;
}
.com-chart-container .arrow-left{
    position: absolute;
    left: -16px;
    top: 50%;
    margin-top: -14px;
}
.com-chart-container .arrow-right{
    position: absolute;
    right: -16px;
    top: 50%;
    margin-top: -14px;
}